import React from 'react'
import {Link} from 'gatsby'
import styles from './styles.module.css'

const CallToAction = props => (
  <section className={styles.cta}>
    <div className={styles.ctaBackground} />

    <Link className={styles.button} to="/download">
      Download
    </Link>
    <span className={styles.label}>For Android, iOS, Windows, macOS, Linux</span>
  </section>
)

export default CallToAction
