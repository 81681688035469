import React from 'react'
import styles from './styles.module.css'
import soapdogImage from '../../../images/soapdog.jpeg'
import ebtessamImage from '../../../images/ebtessam.jpeg'
import bobImage from '../../../images/bobhaugen.jpeg'
import dinosaurImage from '../../../images/dinosaur.jpeg'

const Statement = ({image, author, text, id}) => (
  <div className={styles.statement}>
    <img className={styles.statementA} src={image} alt="Avatar" />
    <div className={styles.statementB}>
      <h1>{author}</h1>
      <p>{text}</p>
      <span>{id}</span>
    </div>
  </div>
)

const SocialGraph = props => (
  <section className={styles.socialGraph}>
    <h1>
      Home to a<br />
      flourishing community
    </h1>
    <div className={styles.content}>
      <Statement
        id={'%eD/2L/ssgpIxL0xajvqQwWyDBlnfoGwpT8t/x5j9zJY=.sha256'}
        author="SoapDog"
        image={soapdogImage}
        text={`Scuttlebutt is the most fun part of the Internet for me. It feels
            like living in the future. It has a melange of different groups and
            cultures all acting together towards building a beautiful community.
            I love it.`}
      />
      <Statement
        id={'%XPRPMO2Dq1qb0LPqots+JCUqkUiHVqYAmiSmGl+HcQw=.sha256'}
        author="Ebtessam Zoheir"
        image={ebtessamImage}
        text={`#scuttleverse Oh how you have grown. Also just read the #patchwork
        release notes. Great work and Thank you! I love this community :D`}
      />
      <Statement
        id={'%lGwRF+yf9nfBz7FPGDv0mA1GrlE1fCqdYs8RfUmMPPk=.sha256'}
        author="bobhaugen"
        image={bobImage}
        text={`Besides the warm and honest and diverse and funny community,
        Scuttlebutt has the most collaborative gang of software developers
        in the known universe.`}
      />
      <Statement
        id={'%EETGAWZj42q79zPgXjAeiBk/bMoNlUE5bIrUj8CIkuo=.sha256'}
        author="dinosaur"
        image={dinosaurImage}
        text={`Over 3 years ago, I joined Scuttlebutt with dreams of a better
        future, where our technology includes humanity, where our economies
        care for relationships, where our spirits celebrate abundance, and
        now these dreams are alive. ☀️🏡🌈`}
      />
    </div>
  </section>
)

export default SocialGraph
