import React from 'react'
import {Link} from 'gatsby'
import styles from './styles.module.css'

const DownloadSection = props => (
  <section className={styles.download}>
    <div className="content">
      <h1>Beta version</h1>
      <p>
        Manyverse already works, but it is still in beta. <Link to="/download">Download it</Link> and give it a try. So far we have built:
      </p>
      <ul>
        <li>
          SSB communication, compatible with <a href="https://scuttlebutt.nz/get-started/">most SSB apps</a>
        </li>
        <li>Threads of public posts and comments</li>
        <li>Emoji reactions</li>
        <li>Add pictures</li>
        <li>Profiles with biographies</li>
        <li>Sync via Wi-Fi, room servers, or pub servers</li>
        <li>Private messaging up to 7 participants</li>
        <li>Hashtags and subscribing to them</li>
        <li>Full-text search</li>
        <li>Dark mode</li>
        <li>Gatherings</li>
        <li><a href="https://manyverse.crowdin.com/manyverse-app">Translated</a> to several languages</li>
        <li>Blocks and mutes</li>
        <li>Content warnings</li>
        <li>Storage management</li>
        <li>Advanced connections management</li>
      </ul>
      <p>
        This is just the beginning. We have many more features planned in the{' '}
        <a href="https://www.manyver.se/roadmap">roadmap</a>,
        but we will need your help to get there.
      </p>
      <p>
        Read our <Link to="/blog">blog</Link> to keep up with updates to this
        project!
      </p>
    </div>
  </section>
)

export default DownloadSection
