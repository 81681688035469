import React from 'react'
import styles from './styles.module.css'
import './animations.css'

const Hero = props => (
  <div className={styles.heroContainer}>
    <svg viewBox="0 0 1600 801" {...props}>
      <defs>
        <linearGradient
          x1="50%"
          y1="94%"
          x2="50%"
          y2="5%"
          id="linearGradient-1"
        >
          <stop stopColor="#A4F7C6" offset="0%" />
          <stop stopColor="#30B5FD" offset="33%" />
          <stop stopColor="#5168FC" offset="66%" />
          <stop stopColor="#633FFB" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="0%"
          y1="50%"
          x2="100%"
          y2="50%"
          id="linearGradient-2"
        >
          <stop stopColor="#fff" stopOpacity={0.1} offset="17%" />
          <stop stopColor="#fff" offset="99%" />
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          id="linearGradient-3"
        >
          <stop stopColor="#fff" stopOpacity={0.1} offset="17%" />
          <stop stopColor="#fff" offset="99%" />
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="100%"
          id="linearGradient-4"
        >
          <stop stopColor="#4847EC" offset="0%" />
          <stop stopColor="#90DED6" offset="100%" />
        </linearGradient>
        <linearGradient
          x1="3%"
          y1="50%"
          x2="75%"
          y2="50%"
          id="linearGradient-5"
        >
          <stop stopColor="#C26648" offset="15%" />
          <stop stopColor="#E9AD60" offset="90%" />
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="105%"
          x2="50%"
          y2="5%"
          id="linearGradient-6"
        >
          <stop stopColor="#7A5F5F" offset="1%" />
          <stop stopColor="#958378" offset="94%" />
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="95%"
          x2="50%"
          y2="-4%"
          id="linearGradient-7"
        >
          <stop stopColor="#958378" offset="0%" />
          <stop stopColor="#CAC1BB" offset="90%" />
        </linearGradient>
        <linearGradient
          x1="50%"
          y1="159%"
          x2="50%"
          y2="-12%"
          id="linearGradient-8"
        >
          <stop stopColor="#958378" offset="15%" />
          <stop stopColor="#CAC1BB" offset="82%" />
        </linearGradient>
        <path
          d="M0.4,67.8 L0.4,47.8 C32.5,47.8 60,28 71.4,0 L92.7,0 C80.3,39.2 43.6,67.8 0.4,67.8 Z"
          id="path-9"
        />
        <path
          d="M92.4,68.2 L92.4,48.2 C60.3,48.2 32.8,28.4 21.4,0.4 L0.1,0.4 C12.4,39.7 49.1,68.2 92.4,68.2 Z"
          id="path-11"
        />
        <rect id="path-13" x={0.7} y={0} width={39.3} height={315.7} />
      </defs>
      <g id="Hero" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <rect
          id="Sky"
          fill="url(#linearGradient-1)"
          fillRule="nonzero"
          x={0}
          y={0}
          width={1600}
          height={519.5}
        />
        <g id="Stars" transform="translate(0, 21)">
          <g
            id="Strong"
            transform="translate(52, 3)"
            fillRule="nonzero"
            fill="#fff"
          >
            <polygon points="6.5 22.3 8 26.7 12.3 28.1 8 29.6 6.5 34 5 29.6 0.7 28.1 5 26.7" />
            <circle cx={73} cy={44.6} r={3.3} />
            <polygon points="299.5 79.2 301 83.5 305.3 85 301 86.5 299.5 90.8 298 86.5 293.7 85 298 83.5" />
            <circle cx={355} cy={9.6} r={3.3} />
            <polygon points="503 0 504.5 4.4 508.8 5.9 504.5 7.3 503 11.7 501.5 7.3 497.2 5.9 501.5 4.4" />
            <polygon points="704 3.7 705.5 8.1 709.8 9.6 705.5 11 704 15.4 702.5 11 698.2 9.6 702.5 8.1" />
            <polygon points="689.3 152.8 690.8 157.2 695.1 158.6 690.8 160.1 689.3 164.5 687.8 160.1 683.5 158.6 687.8 157.2" />
            <circle cx={910.3} cy={163.7} r={3.3} />
            <circle cx={1049.2} cy={20.5} r={3.3} />
            <polygon points="1039.5 131.1 1041 135.5 1045.3 136.9 1041 138.4 1039.5 142.8 1038 138.4 1033.7 136.9 1038 135.5" />
            <polygon points="1170.3 11.4 1171.7 15.7 1176.1 17.2 1171.7 18.7 1170.3 23 1168.8 18.7 1164.4 17.2 1168.8 15.7" />
            <circle cx={1314.8} cy={78.2} r={3.3} />
            <polygon points="1461.9 86.8 1463.4 91.2 1467.7 92.6 1463.4 94.1 1461.9 98.5 1460.4 94.1 1456.1 92.6 1460.4 91.2" />
          </g>
          <g
            id="Weak"
            opacity={0.45}
            transform="translate(18, 1)"
            fillRule="nonzero"
            fill="#fff"
          >
            <polygon points="21.5 118.7 23 126 30.3 127.5 23 128.9 21.5 136.2 20.1 128.9 12.8 127.5 20.1 126" />
            <polygon points="305 15.6 306.4 22.8 313.7 24.3 306.4 25.8 305 33 303.5 25.8 296.2 24.3 303.5 22.8" />
            <polygon points="1098.3 61.3 1099.7 68.6 1107 70 1099.7 71.5 1098.3 78.8 1096.8 71.5 1089.5 70 1096.8 68.6" />
            <polygon points="1525.5 29.1 1526.9 36.4 1534.2 37.9 1526.9 39.3 1525.5 46.6 1524 39.3 1516.7 37.9 1524 36.4" />
            <circle cx={127.7} cy={4.1} r={1.9} />
            <circle cx={251.1} cy={7.9} r={1.9} />
            <circle cx={439.6} cy={49.9} r={1.9} />
            <circle cx={886.9} cy={178.5} r={1.9} />
            <circle cx={660.6} cy={246.3} r={1.9} />
            <circle cx={570.5} cy={229.8} r={1.9} />
            <circle cx={783.8} cy={214.3} r={1.9} />
            <circle cx={1008} cy={142.3} r={1.9} />
            <circle cx={1432.7} cy={60} r={1.9} />
            <circle cx={1541} cy={127.5} r={1.9} />
            <circle cx={1304.1} cy={28.1} r={1.9} />
            <circle cx={863.9} cy={275.8} r={1.9} />
            <circle cx={952.8} cy={222.9} r={1.9} />
            <circle cx={1316.6} cy={122.1} r={1.9} />
            <circle cx={1410.9} cy={26.2} r={1.9} />
            <circle cx={1486} cy={9.7} r={1.9} />
            <circle cx={350.7} cy={51.8} r={1.9} />
            <circle cx={401.7} cy={94.6} r={1.9} />
            <circle cx={385.7} cy={151.8} r={1.9} />
            <circle cx={496.1} cy={15.6} r={1.9} />
            <circle cx={479.9} cy={81.7} r={1.9} />
            <circle cx={704.1} cy={2} r={1.9} />
            <circle cx={903.2} cy={17.4} r={1.9} />
            <circle cx={1122} cy={198} r={1.9} />
            <circle cx={1341.2} cy={172.3} r={1.9} />
            <circle cx={1426.5} cy={142.3} r={1.9} />
            <circle cx={1054} cy={263.8} r={1.9} />
            <circle cx={1534.2} cy={218.7} r={1.9} />
            <circle cx={631.4} cy={33} r={1.9} />
            <circle cx={642.9} cy={178.5} r={1.9} />
            <circle cx={544.7} cy={291.1} r={1.9} />
            <circle cx={591.4} cy={156.6} r={1.9} />
            <circle cx={812.5} cy={160.6} r={1.9} />
            <circle cx={191} cy={49.9} r={1.9} />
            <circle cx={2.6} cy={98.4} r={1.9} />
            <circle cx={4.5} cy={162.5} r={1.9} />
            <circle cx={2.6} cy={6} r={1.9} />
            <circle cx={84.8} cy={9.7} r={1.9} />
            <circle cx={52.9} cy={96.5} r={1.9} />
          </g>
          <path
            d="M93,78.7 L0,78.7 L0,50.1 C10.7,50.1 20.9,49.2 28.7,56.5 C33.3,60.8 35.9,67.3 41.5,70.2 C50.5,75 61.1,68 71.3,67.3 C80.2,66.8 88.7,71 93,78.7 Z"
            fill="#fff"
            fillRule="nonzero"
            opacity={0.45}
          />
          <path
            d="M1500,360 L1600,360 L1600,321.3 C1587.7,317.1 1573.4,323.8 1566.2,334.6 C1563.8,338.2 1561.8,342.3 1558.2,344.7 C1552,348.8 1543.9,346.3 1536.5,345.7 C1523.4,344.7 1507.9,349.4 1500,360 Z"
            fill="#fff"
            fillRule="nonzero"
            opacity={0.45}
          />
          <g id="h-chatbox-l" transform="translate(452, 129)">
            <circle fill="#fff" fillRule="nonzero" cx={3.5} cy={3.7} r={3.3} />
            <circle fill="#fff" fillRule="nonzero" cx={97.2} cy={3.7} r={3.3} />
            <circle fill="#fff" fillRule="nonzero" cx={3.5} cy={54} r={3.3} />
            <circle fill="#fff" fillRule="nonzero" cx={97.2} cy={54} r={3.3} />
            <circle
              fill="#fff"
              fillRule="nonzero"
              cx={79.5}
              cy={73.2}
              r={3.3}
            />
            <polygon
              stroke="#fff"
              strokeWidth={2}
              opacity={0.25}
              points="97.2 54 79.5 54 79.5 73.2 59.3 54 3.5 54 3.5 3.7 97.2 3.7"
            />
            <path
              d="M19.5,21.5 L81.1,21.5"
              stroke="#fff"
              strokeWidth={2}
              opacity={0.25}
            />
            <path
              d="M19.5,36.8 L81.1,36.8"
              stroke="#fff"
              strokeWidth={2}
              opacity={0.25}
            />
          </g>
          <g id="h-chatbox-r" transform="translate(1177, 70)">
            <circle fill="#fff" fillRule="nonzero" cx={87.1} cy={3.8} r={3.3} />
            <circle fill="#fff" fillRule="nonzero" cx={3.4} cy={3.8} r={3.3} />
            <circle
              fill="#fff"
              fillRule="nonzero"
              cx={87.1}
              cy={54.1}
              r={3.3}
            />
            <circle fill="#fff" fillRule="nonzero" cx={3.4} cy={54.1} r={3.3} />
            <circle
              fill="#fff"
              fillRule="nonzero"
              cx={21.1}
              cy={73.3}
              r={3.3}
            />
            <polygon
              stroke="#fff"
              strokeWidth={2}
              opacity={0.25}
              points="3.4 54.1 21.1 54.1 21.1 73.3 41.2 54.1 87.1 54.1 87.1 3.8 3.4 3.8"
            />
            <path
              d="M71.1,21.6 L19.4,21.6"
              stroke="#fff"
              strokeWidth={2}
              opacity={0.25}
            />
            <path
              d="M71.1,36.9 L19.4,36.9"
              stroke="#fff"
              strokeWidth={2}
              opacity={0.25}
            />
          </g>
          <path
            d="M215.7,0.1 L270,57.4 C270.8,58.2 272.1,58.2 272.9,57.4 C273.7,56.6 273.7,55.3 272.9,54.5 L215.7,0.1 Z"
            id="h-comet-l"
            fill="url(#linearGradient-2)"
            fillRule="nonzero"
            opacity={0}
          />
          <path
            d="M1277,200.2 L1219.7,254.5 C1218.9,255.3 1218.9,256.6 1219.7,257.4 C1220.5,258.2 1221.8,258.2 1222.6,257.4 L1277,200.2 Z"
            id="h-comet-r"
            fill="url(#linearGradient-3)"
            fillRule="nonzero"
            opacity={0}
          />
        </g>
        <g id="h-ocean" transform="translate(0, 353)">
          <path
            d="M1600,84.5 L1576.9,84.5 C1566.6,84.5 1556.7,88.6 1549.5,95.9 L1536.6,108.8 C1529.3,116.1 1519.4,120.2 1509.2,120.2 L1405.1,120.2 C1394.8,120.2 1384.9,116.1 1377.7,108.8 L1303.5,34.6 C1296.2,27.3 1286.3,23.2 1276.1,23.2 L1214.3,23.2 C1204,23.2 1194.1,19.1 1186.9,11.8 L1186.9,11.8 C1179.6,4.5 1169.7,0.4 1159.5,0.4 L1046,0.4 C1035.7,0.4 1025.8,4.5 1018.6,11.8 L931.4,99 C924.1,106.3 914.2,110.4 904,110.4 L861.9,110.4 C851.6,110.4 841.7,114.5 834.5,121.8 L825.1,131.2 C817.8,138.5 807.9,142.6 797.7,142.6 L622.4,142.6 C612.1,142.6 602.2,138.5 595,131.2 L535.2,71.4 C527.9,64.1 518,60 507.8,60 L0,60 L0,166.5 L1600,166.5 L1600,84.5 Z"
            id="h-horizon"
            fill="#958378"
            fillRule="nonzero"
            opacity={0.2}
            style={{
              mixBlendMode: 'multiply',
            }}
          />
          <rect
            id="Water"
            fill="url(#linearGradient-4)"
            fillRule="nonzero"
            x={0}
            y={166.2}
            width={1600}
            height={183.9}
          />
          <rect
            id="Sand"
            fill="url(#linearGradient-5)"
            fillRule="nonzero"
            x={0}
            y={349.5}
            width={1600}
            height={97.5}
          />
          <g
            id="h-wave6"
            opacity={0}
            transform="translate(414, 211)"
            fillRule="nonzero"
            fill="#fff"
          >
            <path d="M0.3,0.3 C1.9,4.4 5.9,7.3 10.5,7.3 C15.1,7.3 19.1,4.4 20.7,0.3 L0.3,0.3 Z" />
            <path d="M177.3,0.3 C178.9,4.4 182.9,7.3 187.5,7.3 C192.1,7.3 196.1,4.4 197.7,0.3 L177.3,0.3 Z" />
            <path d="M197.8,0.3 C199.4,4.4 203.4,7.3 208,7.3 C212.6,7.3 216.6,4.4 218.2,0.3 L197.8,0.3 Z" />
          </g>
          <g
            id="h-wave5"
            opacity={0}
            transform="translate(952, 211)"
            fillRule="nonzero"
            fill="#fff"
          >
            <path d="M0.9,0.3 C2.5,4.4 6.5,7.3 11.1,7.3 C15.8,7.3 19.7,4.4 21.3,0.3 L0.9,0.3 Z" />
            <path d="M21.3,0.3 C22.9,4.4 26.9,7.3 31.5,7.3 C36.1,7.3 40.1,4.4 41.7,0.3 L21.3,0.3 Z" />
            <path d="M41.8,0.3 C43.4,4.4 47.4,7.3 52,7.3 C56.6,7.3 60.6,4.4 62.2,0.3 L41.8,0.3 Z" />
          </g>
          <g
            id="h-wave4"
            opacity={0}
            transform="translate(691, 310)"
            fillRule="nonzero"
            fill="#fff"
          >
            <path d="M0.4,0.3 C2,4.4 6,7.3 10.6,7.3 C15.3,7.3 19.2,4.4 20.8,0.3 L0.4,0.3 Z" />
            <path d="M20.9,0.3 C22.5,4.4 26.5,7.3 31.1,7.3 C35.8,7.3 39.7,4.4 41.3,0.3 L20.9,0.3 Z" />
            <path d="M41.3,0.3 C42.9,4.4 46.9,7.3 51.5,7.3 C56.1,7.3 60.1,4.4 61.7,0.3 L41.3,0.3 Z" />
          </g>
          <g
            id="h-wave3"
            opacity={0}
            transform="translate(973, 251)"
            fillRule="nonzero"
            fill="#fff"
          >
            <path d="M0.3,0.3 C1.9,4.4 5.9,7.3 10.5,7.3 C15.1,7.3 19.1,4.4 20.7,0.3 L0.3,0.3 Z" />
            <path d="M20.8,0.3 C22.4,4.4 26.4,7.3 31,7.3 C35.6,7.3 39.6,4.4 41.2,0.3 L20.8,0.3 Z" />
          </g>
          <g
            id="h-wave2"
            opacity={0}
            transform="translate(830, 288)"
            fillRule="nonzero"
            fill="#fff"
          >
            <path d="M0.3,0.7 C1.9,4.8 5.9,7.7 10.5,7.7 C15.2,7.7 19.1,4.8 20.7,0.7 L0.3,0.7 Z" />
            <path d="M20.7,0.7 C22.3,4.8 26.3,7.7 30.9,7.7 C35.6,7.7 39.5,4.8 41.1,0.7 L20.7,0.7 Z" />
          </g>
          <g
            id="h-wave1"
            opacity={0}
            transform="translate(592, 288)"
            fillRule="nonzero"
            fill="#fff"
          >
            <path d="M0.8,0.7 C2.4,4.8 6.4,7.7 11,7.7 C15.7,7.7 19.6,4.8 21.2,0.7 L0.8,0.7 Z" />
            <path d="M21.2,0.7 C22.8,4.8 26.8,7.7 31.4,7.7 C36.1,7.7 40,4.8 41.6,0.7 L21.2,0.7 Z" />
          </g>
          <g
            id="SandDots"
            opacity={0.5}
            transform="translate(395, 364)"
            fillRule="nonzero"
            fill="#fff"
          >
            <circle opacity={0.5} cx={162} cy={12} r={1.9} />
            <circle opacity={0.5} cx={108.5} cy={4.6} r={1.9} />
            <circle opacity={0.5} cx={178.9} cy={28.8} r={1.9} />
            <circle opacity={0.5} cx={256.3} cy={62.8} r={1.9} />
            <circle opacity={0.5} cx={270} cy={25.1} r={1.9} />
            <circle opacity={0.5} cx={349.4} cy={68.3} r={1.9} />
            <circle opacity={0.5} cx={95.2} cy={64.6} r={1.9} />
            <circle opacity={0.5} cx={165.7} cy={68.3} r={1.9} />
            <circle opacity={0.5} cx={2.8} cy={27} r={1.9} />
            <circle opacity={0.5} cx={196.3} cy={2.9} r={1.9} />
            <circle opacity={0.5} cx={228.4} cy={20.9} r={1.9} />
            <circle opacity={0.5} cx={337.3} cy={2.7} r={1.9} />
            <circle opacity={0.5} cx={395.9} cy={2.9} r={1.9} />
            <circle opacity={0.5} cx={569.4} cy={6.4} r={1.9} />
            <circle opacity={0.5} cx={519.9} cy={4.6} r={1.9} />
            <circle opacity={0.5} cx={435.3} cy={66.5} r={1.9} />
            <circle opacity={0.5} cx={559} cy={36} r={1.9} />
            <circle opacity={0.5} cx={588.5} cy={62.8} r={1.9} />
            <circle opacity={0.5} cx={30.5} cy={2.9} r={1.9} />
            <circle opacity={0.5} cx={29.5} cy={64.6} r={1.9} />
            <circle opacity={0.5} cx={4.7} cy={6.4} r={1.9} />
          </g>
          <g id="Boat" transform="translate(687, 42)" fillRule="nonzero">
            <path
              d="M22.5,215.5 C34.8,215.5 34.8,209.5 47,209.5 C59.3,209.5 59.3,215.5 71.5,215.5 C83.8,215.5 83.8,209.5 96,209.5 C108.3,209.5 108.3,215.5 120.5,215.5 C132.8,215.5 132.8,209.5 145,209.5 C157.2,209.5 157.3,215.5 169.5,215.5 C181.8,215.5 181.8,209.5 194,209.5 C202.7,209.5 205.2,212.5 210.3,214.2 C216.6,210.6 221.8,205.2 225.1,198.5 L0.7,198.5 L6.8,211 C11.5,212.8 14.2,215.5 22.5,215.5 Z"
              fill="#4847EC"
            />
            <rect fill="#4847EC" x={101.5} y={0.6} width={4.9} height={198.9} />
            <polygon
              fill="#DAD4D0"
              points="101.5 42.5 14.1 198.5 101.5 198.5"
            />
            <polygon
              fill="#DAD4D0"
              points="106.4 0.6 217.2 198.5 106.4 198.5"
            />
            <polygon
              fill="#E9AD60"
              points="57.8 27 101.5 27 101.5 0.6 57.8 0.6 74.2 13.8"
            />
            <path
              d="M149.2,140.6 C149.9,141.4 151.2,141.5 152,140.7 L162.3,129.9 L162.3,120.9 L155.5,120.9 L155.5,130.6 L151.1,134.9 L144.5,125.3 C143.8,124.3 142.7,123.8 141.5,123.8 L136.9,123.8 L136.9,121.4 L139.8,120.7 C140.6,120.5 141.2,119.6 141,118.8 L140.5,116.9 L141.7,116.1 L139.9,111.4 L146.3,109 C145.9,108.3 144.9,107.9 144.1,108.2 L139.3,110 L139.2,109.9 C138.3,108.5 136.8,107.6 135.2,107.5 C131.4,107.3 128.7,110.6 129.4,114 L131.4,123.8 L124.9,123.9 C123.8,123.9 122.7,124.5 122,125.4 L113.2,137.6 C112.7,138.3 112.7,139.3 113.4,140 L123.8,151.1 L123.8,155 L127.2,153.6 L127.7,189.4 L128.9,189.4 L128.9,198.6 L137.1,198.6 L134.1,195.3 L134.1,189.4 L135.5,189.4 L135.5,160.8 L137.7,160.8 L144.3,189.4 L145.9,198.6 L157,198.6 L151.1,195.3 L149.7,189.4 L151.3,189.4 L142.5,149.5 L142.5,133.2 L149.2,140.6 Z M120.2,139.3 L124.8,133.4 L126.8,146.1 L120.2,139.3 Z"
              id="Person"
              fill="#4847EC"
            />
          </g>
          <g transform="translate(682, 84)">
            <g id="h-boat-aura-o" opacity={0}>
              <circle
                fill="#fff"
                fillRule="nonzero"
                cx={32.6}
                cy={38.4}
                r={3.8}
              />
              <circle
                fill="#fff"
                fillRule="nonzero"
                cx={207.3}
                cy={38.4}
                r={3.8}
              />
              <g stroke="#fff" strokeWidth={2}>
                <path d="M58,17.8 C76,6.8 97.2,0.5 119.9,0.5" />
                <path d="M0.4,119.9 C0.4,97.9 6.4,77.2 16.8,59.5" />
                <path d="M181.7,17.8 C163.7,6.8 142.5,0.5 119.8,0.5" />
                <path d="M239.3,119.9 C239.3,97.9 233.3,77.2 222.9,59.5" />
              </g>
            </g>
            <g id="h-boat-aura-i" opacity={0}>
              <circle
                fill="#fff"
                fillRule="nonzero"
                cx={119.9}
                cy={24.2}
                r={3.8}
              />
              <circle
                fill="#fff"
                fillRule="nonzero"
                cx={24.2}
                cy={119.9}
                r={3.8}
              />
              <circle
                fill="#fff"
                fillRule="nonzero"
                cx={215.6}
                cy={119.9}
                r={3.8}
              />
              <g stroke="#fff" strokeWidth={2}>
                <path d="M27.7,94.1 C36.4,62.9 60.6,38.2 91.4,28.6" />
                <path d="M212,94.1 C203.3,62.9 179.1,38.2 148.3,28.6" />
              </g>
            </g>
          </g>
        </g>
        <g id="Mountain" transform="translate(999, 234)">
          <g id="Seagull" transform="translate(177, 209)" fillRule="nonzero">
            <rect fill="#E9AD60" x={22.2} y={31.2} width={1.9} height={12.6} />
            <rect
              fill="#4847EC"
              opacity={0.25}
              x={22.2}
              y={31.2}
              width={1.9}
              height={5.2}
            />
            <path
              d="M28.2,12.6 L0.4,32.4 L22.3,32.4 C30.7,32.4 37.5,25.6 37.5,17.2 L37.5,17.2 C37.5,14.4 36.8,11.7 35.3,9.4 L35,8.8 L38.2,7.1 L38.2,4.3 L36.4,2.5 C35.3,1.4 33.9,0.8 32.4,0.8 L32.4,0.8 C28.6,0.8 25.9,4.5 26.9,8.1 L28.2,12.6 Z"
              fill="#fff"
            />
            <path
              d="M37.5,17.3 C37.5,17.3 33.9,27.2 24,27.2 C21.1,27.2 14.5,27.2 14.5,27.2 L14.5,32.5 L22.3,32.5 C30.7,32.4 37.5,25.6 37.5,17.3 Z"
              fill="#91A7FF"
              opacity={0.25}
            />
            <path
              d="M24.6,15.2 L27.3,17.9 C29.2,19.8 29.1,22.9 27,24.7 L17.9,32.5 L0.5,32.5 L24.6,15.2 Z"
              fill="#CAC1BB"
            />
            <circle fill="#E9AD60" cx={34.2} cy={4.4} r={1} />
            <polygon fill="#4847EC" points="0.4 32.4 6.1 28.4 10.1 32.4" />
            <path
              d="M38.2,7.2 L44.5,7.2 L44.1,6.7 C43.5,5.9 42.7,5.3 41.7,5.1 L38.1,4.4 L38.1,5.8 L36.3,5.8 L38.2,7.2 Z"
              fill="#E9AD60"
            />
          </g>
          <polygon
            fill="url(#linearGradient-6)"
            fillRule="nonzero"
            points="0.269855956 566 199.2 248.8 258.5 308.1 350.3 160.1 468.1 160.1 563.8 355.5 601 318.234029 601 566"
          />
          <path
            d="M601,463.3 L232.4,463.3 C237.2,453.6 247.4,447.6 258.2,446 C269,444.4 280,445.5 290.7,443.9 C325.2,438.9 351.4,408.6 385.4,401.3 C423.7,393.1 462.6,415.6 501.6,412.6 C536.180704,410.052979 568.93805,388.504653 601.000168,400.224981 L601,463.3 Z"
            fill="#fff"
            fillRule="nonzero"
            opacity={0.15}
          />
          <polygon
            fill="url(#linearGradient-7)"
            fillRule="nonzero"
            points="128.093427 566 93.9 477.5 123 477.5 93.9 416.8 154.8 416.8 154.8 372.1 204.8 322.2 184.4 322.2 184.4 302.3 199.2 287.1 199.2 248.8 0.264570026 566"
          />
          <path
            d="M601,434.636334 C556.048276,441.011162 503.942927,416.564381 463.6,439.4 C442.1,451.6 429,475 408,488 C374.5,508.8 331,498.3 292.3,505.7 C257.269466,512.355801 225.690259,535.172882 207.771864,566 L601,566 L601,434.636334 Z"
            fill="#fff"
            fillRule="nonzero"
            opacity={0.15}
          />
          <polygon
            fill="url(#linearGradient-8)"
            fillRule="nonzero"
            points="258.5 308.1 291.8 341.3 323.9 341.3 408 257.9 372.6 257.9 372.6 214.9 351.3 193.7 363 172.7 350.3 160.1"
          />
          <g transform="translate(390, 417)" stroke="#7A5F5F" strokeWidth={6}>
            <path d="M18.9,127.7 L18.9,0" />
            <g>
              <path d="M0.6,18.4 C10.7,18.4 18.9,10.2 18.9,0.1" />
              <path d="M37.3,18.4 C27.2,18.4 19,10.2 19,0.1" />
            </g>
            <g transform="translate(0, 23)">
              <path d="M0.6,18.5 C10.7,18.5 18.9,10.3 18.9,0.2" />
              <path d="M37.3,18.5 C27.2,18.5 19,10.3 19,0.2" />
            </g>
            <g transform="translate(0, 46)">
              <path d="M0.6,18.7 C10.7,18.7 18.9,10.5 18.9,0.4" />
              <path d="M37.3,18.7 C27.2,18.7 19,10.5 19,0.4" />
            </g>
            <g transform="translate(0, 69)">
              <path d="M0.6,18.8 C10.7,18.8 18.9,10.6 18.9,0.5" />
              <path d="M37.3,18.8 C27.2,18.8 19,10.6 19,0.5" />
            </g>
            <g transform="translate(0, 92)">
              <path d="M0.6,19 C10.7,19 18.9,10.8 18.9,0.7" />
              <path d="M37.3,19 C27.2,19 19,10.8 19,0.7" />
            </g>
            <path d="M124.3,79.2 L124.3,21" />
            <g transform="translate(106, 21)">
              <path d="M0,18.3 C10.1,18.3 18.3,10.1 18.3,0" />
              <path d="M36.6,18.3 C26.5,18.3 18.3,10.1 18.3,0" />
            </g>
            <g transform="translate(106, 44)">
              <path d="M0,18.5 C10.1,18.5 18.3,10.3 18.3,0.2" />
              <path d="M36.6,18.5 C26.5,18.5 18.3,10.3 18.3,0.2" />
            </g>
          </g>
          <g transform="translate(289, 477)" fillRule="nonzero" fill="#7A5F5F">
            <polygon points="0.481203087 89 32.2 57.3 73.8 57.3 89.9 73.5 144.9 78.1 201.3 18 269.7 18 286.8 0.9 312 0.9 312 89" />
            <path d="M245.3,18.3 L262.1,18.3 C260.6,15.1 257.4,12.9 253.7,12.9 C250,12.9 246.7,15.1 245.3,18.3 Z" />
            <path d="M162.580764,89 C154.817796,73.2092773 138.685209,62.4 119.9,62.4 C101.21002,62.3026564 84.983744,73.1972239 77.3090619,88.995813 L162.580764,89 Z" />
          </g>
          <polygon
            fill="#AFA29A"
            fillRule="nonzero"
            points="353.8 341.3 380.9 314.2 392.6 325.8 427.8 290.7 427.8 341.3"
          />
          <path
            d="M400.4,160.4 C397.7,154.4 391.8,150.3 384.8,150.3 C377.8,150.3 371.9,154.5 369.2,160.4 L400.4,160.4 Z"
            fill="#7A5F5F"
            fillRule="nonzero"
          />
          <path
            d="M446.8,160.4 C444.1,154.4 438.2,150.3 431.2,150.3 C424.2,150.3 418.3,154.5 415.6,160.4 L446.8,160.4 Z"
            fill="#7A5F5F"
            fillRule="nonzero"
          />
          <path
            d="M349.1,123.3 C359.2,145.8 381.7,161.5 408,161.5 C434.3,161.5 456.8,145.8 466.9,123.3 L349.1,123.3 Z"
            fill="#5E7D5E"
            fillRule="nonzero"
          />
          <path
            d="M453.4,81.1 C451.2,74.2 444.8,69.6 437.6,69.6 L426.7,69.6 L397.2,69.6 L378.3,69.6 C371.1,69.6 364.7,74.2 362.5,81.1 L349,123.2 L381,123.2 L384.7,123.2 L431.1,123.2 L434.8,123.2 L466.8,123.2 L453.4,81.1 Z M368.1,112.2 L374,97.2 L378.1,112.2 L368.1,112.2 Z M437.9,112.2 L442,97.2 L447.9,112.2 L437.9,112.2 Z"
            fill="#fff"
            fillRule="nonzero"
          />
          <path
            d="M408,90 L408,90 C399.8,90 393.2,83.4 393.2,75.2 L393.2,65.8 C393.2,57.6 399.8,51 408,51 L408,51 C416.2,51 422.8,57.6 422.8,65.8 L422.8,75.2 C422.8,83.3 416.2,90 408,90 Z"
            fill="#91A7FF"
            fillRule="nonzero"
            opacity={0.25}
          />
          <path
            d="M408,79.4 L408,79.4 C399.8,79.4 393.2,72.8 393.2,64.6 L393.2,55.2 C393.2,47 399.8,40.4 408,40.4 L408,40.4 C416.2,40.4 422.8,47 422.8,55.2 L422.8,64.6 C422.8,72.7 416.2,79.4 408,79.4 Z"
            fill="#F3CFD3"
            fillRule="nonzero"
          />
          <path
            d="M408,85.3 L408,85.3 C399.8,85.3 393.2,78.7 393.2,70.5 L422.8,70.5 C422.8,78.7 416.2,85.3 408,85.3 Z"
            fill="#E9AD60"
            fillRule="nonzero"
          />
          <path
            d="M408,67.1 L408,67.1 C412.1,67.1 415.3,70.4 415.3,74.4 L400.6,74.4 C400.7,70.4 404,67.1 408,67.1 Z"
            fill="#E9AD60"
            fillRule="nonzero"
          />
          <path
            d="M408,40.4 C399.8,40.4 393.2,47 393.2,55.2 L393.2,55.6 L422.8,55.6 L422.8,55.2 C422.8,47 416.2,40.4 408,40.4 Z"
            fill="#C26648"
            fillRule="nonzero"
          />
          <rect
            fill="#C26648"
            fillRule="nonzero"
            x={392.1}
            y={52.6}
            width={31.8}
            height={6.1}
          />
          <path
            d="M393.5,52.6 L422.6,52.6 C422.4,51.5 422.1,50.4 421.7,49.4 L394.5,49.4 C394,50.4 393.6,51.5 393.5,52.6 Z"
            fill="#C26648"
            fillRule="nonzero"
            opacity={0.25}
          />
          <rect
            fill="#E9AD60"
            fillRule="nonzero"
            x={393.2}
            y={58.8}
            width={4.1}
            height={12.6}
          />
          <path
            d="M394.2,123.3 L378.1,123.3 L378.1,112.2 L379.3,112.2 C386.1,112.2 392.2,116.7 394.2,123.3 L394.2,123.3 Z"
            fill="#F3CFD3"
            fillRule="nonzero"
          />
          <path
            d="M421.9,123.3 L438,123.3 L438,112.2 L436.8,112.2 C429.9,112.2 423.8,116.7 421.9,123.3 L421.9,123.3 Z"
            fill="#F3CFD3"
            fillRule="nonzero"
          />
          <rect
            fill="#E9AD60"
            fillRule="nonzero"
            x={418.7}
            y={58.8}
            width={4.1}
            height={12.6}
          />
          <rect
            fill="#91A7FF"
            fillRule="nonzero"
            x={384.8}
            y={90.4}
            width={46.4}
            height={32.9}
          />
          <circle
            fill="#4847EC"
            fillRule="nonzero"
            opacity={0.25}
            cx={408}
            cy={106.8}
            r={3.4}
          />
          <polygon
            fill="#91A7FF"
            fillRule="nonzero"
            opacity={0.25}
            points="368.1 112.2 360.2 112.2 373.9 97.2"
          />
          <polygon
            fill="#91A7FF"
            fillRule="nonzero"
            opacity={0.25}
            points="448 112.2 455.8 112.2 442.1 97.2"
          />
          <rect
            fill="#EEBBC0"
            fillRule="nonzero"
            x={406.2}
            y={60.6}
            width={3.7}
            height={6.5}
          />
          <path
            d="M403.5,71.7 C404.4,73.3 406.1,74.5 408,74.5 C409.9,74.5 411.7,73.4 412.5,71.7 L403.5,71.7 Z"
            fill="#F3CFD3"
            fillRule="nonzero"
          />
          <g transform="translate(386, 123)" fillRule="nonzero" fill="#506153">
            <polygon points="22 21.6 0.7 0.3 43.4 0.3" />
            <path d="M26.1,13.1 L22,21.6 L37.1,36.7 C40.4,35.9 43.5,34.9 46.6,33.6 L26.1,13.1 Z" />
          </g>
          <rect
            fill="#DE7782"
            fillRule="nonzero"
            opacity={0.25}
            x={393.2}
            y={58.8}
            width={29.6}
            height={1.8}
          />
          <g transform="translate(301, 0)">
            <g id="h-mountain-aura-o" opacity={0}>
              <circle
                fill="#fff"
                fillRule="nonzero"
                cx={29.3}
                cy={34.3}
                r={3.4}
              />
              <circle
                fill="#fff"
                fillRule="nonzero"
                cx={184.9}
                cy={34.3}
                r={3.4}
              />
              <g stroke="#fff" strokeWidth={2}>
                <path d="M51.9,15.8 C68,6 86.8,0.4 107,0.4" />
                <path d="M0.7,106.8 C0.7,87.2 6,68.8 15.3,53" />
                <path d="M162.1,15.8 C146,6 127.2,0.4 107,0.4" />
                <path d="M213.3,106.8 C213.3,87.2 208,68.8 198.7,53" />
              </g>
            </g>
            <g id="h-mountain-aura-i" opacity={0}>
              <circle
                fill="#fff"
                fillRule="nonzero"
                cx={107}
                cy={21.6}
                r={3.4}
              />
              <circle
                fill="#fff"
                fillRule="nonzero"
                cx={21.8}
                cy={106.8}
                r={3.4}
              />
              <circle
                fill="#fff"
                fillRule="nonzero"
                cx={192.2}
                cy={106.8}
                r={3.4}
              />
              <g stroke="#fff" strokeWidth={2}>
                <path d="M24.9,83.8 C32.7,56 54.2,34 81.6,25.5" />
                <path d="M189.1,83.8 C181.3,56 159.8,34 132.4,25.5" />
              </g>
            </g>
          </g>
        </g>
        <g id="Jungle" transform="translate(0, 103)">
          <path
            d="M271.9,0.7 L144.4,0.7 C123.6,0.7 104.2,11.3 92.8,28.7 L49.8,95.1 C43.4,105 32.4,111 20.6,111 L0,111 L0,386.7 L26,412.7 C37.5,424.2 53.1,430.7 69.4,430.7 L94.9,430.7 C111.2,430.7 126.8,437.2 138.3,448.7 L138.3,448.7 C149.8,460.2 165.4,466.7 181.7,466.7 L236.3,466.7 C255.7,466.7 274,457.5 285.6,441.9 L316.3,400.7 C322.9,391.9 333.2,386.7 344.2,386.7 L380,386.7 C396.3,386.7 411.9,380.2 423.4,368.7 L490,302.1 C501.5,290.6 508,275 508,258.7 L508,170 C508,150.8 492.4,135.2 473.2,135.2 L449.2,135.2 C438.2,135.2 427.8,130 421.2,121.2 L391.7,81.6 C385.1,72.8 374.8,67.6 363.7,67.6 L363.7,67.6 C349.7,67.6 337.1,59.2 331.6,46.4 L328.2,38.4 C318.9,15.4 296.6,0.7 271.9,0.7 Z"
            fill="#6DB28C"
            fillRule="nonzero"
          />
          <path
            d="M508.2,258.6 L508.2,254 C487.1,297.1 429.9,315.6 393.1,276.3 C383,265.6 368.7,258.5 354,258.6 C339.3,258.7 324.5,266.5 317.4,279.4 C311.8,289.5 328.4,292.1 146.8,292 C139.7,273.8 139.8,258.1 123.8,247 C107.7,235.9 74.2,231 58.8,243.1 C45.6,228.2 18.9,223.3 0,232 L0,386.7 L26,412.7 C37.5,424.2 53.1,430.7 69.4,430.7 L94.9,430.7 C111.2,430.7 126.8,437.2 138.3,448.7 L138.3,448.7 C149.8,460.2 165.4,466.7 181.7,466.7 L236.3,466.7 C255.7,466.7 274,457.5 285.6,441.9 L316.3,400.7 C322.9,391.9 333.2,386.7 344.2,386.7 L380,386.7 C396.3,386.7 411.9,380.2 423.4,368.7 L490,302.1 C501.7,290.6 508.2,274.9 508.2,258.6 Z"
            fill="#5A9E77"
            fillRule="nonzero"
          />
          <g transform="translate(221, 297)">
            <mask id="mask-10" fill="#fff">
              <use xlinkHref="#path-9" />
            </mask>
            <use
              id="Mask2"
              fill="#C26648"
              fillRule="nonzero"
              xlinkHref="#path-9"
            />
            <g
              opacity={0.15}
              mask="url(#mask-10)"
              stroke="#fff"
              strokeWidth={2}
            >
              <g transform="translate(33, -62)">
                <path d="M1.2,20.7 C6.5,25.1 11.8,20.6 17.7,20.7 C20,20.7 22.6,21.3 23.9,23.3 C24.7,24.7 24.7,26.4 24.6,28 C24.1,36.6 16.6,39.3 11.6,45.5 C6.1,52.4 5.3,61.7 4.7,70.5" />
                <path d="M33.3,0.5 C32,5.1 33.5,10.4 37.1,13.5 C40.7,16.6 46.1,17.5 50.5,15.6" />
                <path d="M49.1,31.5 C45.1,32.3 40.9,33.1 37.3,34.9 C33.6,36.7 30.3,39.8 29.2,43.7 C26.6,52.7 29.1,62.4 26.4,71.3 C25.8,73.2 25,75 24.2,76.7 C20,86 16.2,95.9 19.1,106.3 C19.8,108.9 21.7,111.4 24.4,111.9 C25.4,112.1 26.5,112 27.6,111.9 C35.6,110.9 43.3,106.9 51.4,107.3" />
                <path d="M52.6,79.9 C46.4,80.2 40.1,82.2 35.8,86.6 C34.5,88 33.3,89.7 33.1,91.6 C32.8,93.5 33.6,95.6 35.3,96.6 C36.1,97 36.9,97.1 37.8,97.2 C42.4,97.3 47.2,94.3 51.3,96.5" />
                <path d="M50.1,119.3 C45.9,121.2 41.2,121 36.6,121.3 C32,121.6 27.1,122.7 24.3,126.4 C19.9,132.1 23.3,140.8 20.3,147.3 C18.7,150.9 15.4,153.4 11.8,154.9 C8.2,156.4 4.3,157 0.5,157.5" />
                <path d="M48.5,144.8 C41.5,143.2 33.6,145.9 29,151.5 C24.4,157.1 23.4,165.4 26.5,171.9 C28.5,176.1 31.9,179.6 33.2,184.1 C34.3,187.9 33.6,192 33.5,196 C33.4,200 33.9,204.3 36.8,207.1 C40.2,210.3 45.6,210.2 50.1,209.2 C50.9,209 51.8,207.9 51,207.6" />
                <path d="M6.1,185.5 C13.9,187.1 20.4,194 21.5,201.9 C22.6,209.8 19.2,219.2 24.5,225.2 C25.5,226.3 26.7,227.2 27.3,228.6 C27.9,229.9 27.9,231.4 27.8,232.8 C27.2,251 26.6,269.3 26,287.5 C25.9,291.7 25.2,296.8 21.5,298.8" />
                <path d="M2.6,226.9 C5.2,228 7.8,229.1 10.5,230.2 C11.5,230.6 12.6,231.1 13.4,231.9 C14.7,233.1 15.3,235 15.5,236.8 C15.9,240.1 15.4,243.4 14.9,246.6 C13.7,254.2 12.5,261.8 11.2,269.4" />
                <path d="M52.1,227.7 C46.3,229.5 39.9,231.8 37.3,237.3 C36.1,239.9 36,242.9 35.9,245.8 C35.8,249.2 35.7,252.5 35.6,255.9 C35.5,258.7 35.4,261.6 36.1,264.3 C37.2,269.4 40.8,273.8 45.5,276 C46.2,276.3 47.3,276 47,275.4" />
              </g>
            </g>
          </g>
          <g transform="translate(129, 374)">
            <g>
              <mask id="mask-12" fill="#fff">
                <use xlinkHref="#path-11" />
              </mask>
              <use
                id="Mask3"
                fill="#C26648"
                fillRule="nonzero"
                xlinkHref="#path-11"
              />
              <g
                opacity={0.15}
                mask="url(#mask-12)"
                stroke="#fff"
                strokeWidth={2}
              >
                <g transform="translate(-53, -52)">
                  <path d="M0.3,39.1 C7.2,38.5 7.8,31.5 11.9,27.4 C13.6,25.8 15.8,24.3 18.1,24.9 C19.7,25.3 20.9,26.5 21.9,27.7 C27.6,34.2 24.2,41.4 25.1,49.2 C26.1,57.9 32.1,65.1 37.9,71.7" />
                  <path d="M8.6,2.1 C10.9,6.3 15.7,8.9 20.5,8.6 C25.3,8.3 29.7,5 31.4,0.6" />
                  <path d="M41.7,12.9 C39.4,16.3 37,19.8 35.7,23.7 C34.4,27.6 34.2,32.1 36.2,35.7 C40.7,44 49.4,48.9 53.8,57.2 C54.7,58.9 55.4,60.8 56.1,62.6 C59.7,72.2 64,81.8 73.4,87.1 C75.8,88.4 78.9,88.8 81.1,87.3 C82,86.7 82.7,85.9 83.4,85 C88.4,78.6 91,70.4 96.9,64.9" />
                  <path d="M78.4,44.6 C74.2,49.2 71.1,55.1 71.3,61.3 C71.3,63.2 71.7,65.2 72.9,66.7 C74,68.2 76.1,69.2 77.9,68.7 C78.7,68.5 79.4,67.9 80.1,67.3 C83.4,64.1 84.7,58.6 89.1,57.3" />
                  <path d="M104.5,74.3 C102.9,78.6 99.4,81.8 96.4,85.2 C93.4,88.6 90.7,92.9 91.3,97.5 C92.3,104.6 100.7,108.4 103.3,115.1 C104.7,118.7 104.2,122.9 102.7,126.5 C101.2,130.1 98.9,133.2 96.5,136.3" />
                  <path d="M121.4,93.4 C115.3,97.2 111.6,104.7 112.3,111.9 C113,119.1 118.2,125.7 125,128.1 C129.4,129.7 134.3,129.7 138.4,132 C141.9,133.9 144.3,137.3 147,140.2 C149.7,143.1 153.2,145.8 157.2,145.7 C161.9,145.6 165.6,141.7 168.1,137.8 C168.6,137.1 168.4,135.6 167.6,136" />
                  <path d="M120.2,152.2 C126.9,147.8 136.3,148.1 142.7,152.9 C149.1,157.7 153.3,166.7 161.3,167.3 C162.8,167.4 164.3,167.2 165.7,167.7 C167.1,168.2 168.1,169.3 169.1,170.3 C181.6,183.6 194,197 206.5,210.3 C209.4,213.4 212.5,217.4 211.3,221.4" />
                  <path d="M147,183.9 C149.6,182.8 152.3,181.8 154.9,180.7 C155.9,180.3 157,179.8 158.1,179.8 C159.9,179.7 161.6,180.7 163,181.8 C165.6,183.8 167.6,186.5 169.5,189.2 C174,195.4 178.5,201.7 183.1,207.9" />
                  <path d="M182.6,149.4 C179.8,154.8 176.9,161 179,166.6 C180,169.3 182,171.5 184,173.6 C186.3,176.1 188.6,178.5 190.9,181 C192.8,183.1 194.8,185.1 197.2,186.6 C201.6,189.4 207.3,190 212.1,188.2 C212.8,188 213.4,186.9 212.7,186.7" />
                </g>
              </g>
            </g>
          </g>
          <g transform="translate(490, 416)" fillRule="nonzero">
            <g transform="translate(34, 60)" fill="#7A5F5F">
              <polygon points="13.2 99.8 5.7 99 5.7 0 13.2 0" />
              <path d="M9.5,23.4 L9.5,23.4 C4.4,23.4 0.3,19.3 0.3,14.2 L0.3,0 L18.7,0 L18.7,14.2 C18.7,19.3 14.5,23.4 9.5,23.4 Z" />
            </g>
            <polygon
              fill="#506153"
              points="86.7 60 64.9 45.9 86.7 31.9 60.8 33.3 70.2 9.1 50.1 25.5 43.5 0.5 36.8 25.5 16.7 9.1 26.1 33.3 0.2 31.9 22 45.9 0.2 60"
            />
          </g>
          <g transform="translate(422, 356)" fillRule="nonzero">
            <g transform="translate(34, 60)" fill="#7A5F5F">
              <polygon points="13 137.1 5.6 136.4 5.6 0.5 13 0.5" />
              <path d="M9.3,23.9 L9.3,23.9 C4.2,23.9 0.1,19.8 0.1,14.7 L0.1,0.5 L18.5,0.5 L18.5,14.7 C18.5,19.8 14.4,23.9 9.3,23.9 Z" />
            </g>
            <polygon
              fill="#506153"
              points="86.6 60.5 64.8 46.4 86.6 32.4 60.7 33.8 70 9.6 50 26 43.3 0.9 36.7 26 16.6 9.6 26 33.8 0.1 32.4 21.9 46.4 0.1 60.5"
            />
          </g>
          <path
            d="M111,560.3 L505.8,560.3 C535.9,560.3 564.1,575.2 581,600.1 L111,600.1 L111,560.3 Z"
            fill="#C26648"
            fillRule="nonzero"
          />
          <path
            d="M25.3,490.6 C36.4,476.8 53.3,467.6 70.9,465.8 C88.5,464 106.9,469.5 120.6,480.9 C127.9,486.9 134.6,494.8 143.9,496.6 C153.4,498.4 162.6,493.2 171.9,490.8 C183.4,487.7 196,488.8 206.8,493.9 C214.5,497.5 222.6,503.1 230.7,500.4 C235.6,498.8 239,494.4 242.6,490.8 C252.9,480.5 267.4,474.5 282,474.7 C285.7,474.7 289.5,475.1 292.9,473.9 C298.1,472 301.3,466.9 304.5,462.3 C310.8,453.2 319.2,445 329.8,442 C340.4,439 353.5,442.9 358.3,452.8 C360,456.2 360.6,460.2 362.5,463.5 C369.8,476.4 390.1,473.7 401.1,483.7 C411,492.7 410.7,509.8 421.3,517.9 C429.7,524.3 441.3,522.6 451.8,523.6 C472.5,525.7 491.3,540.7 498,560.3 L20,561.7 C23,554.5 23.1,544.3 24.7,536.7 C27.7,522.3 27.5,505.2 25.3,490.6 Z"
            fill="#506153"
            fillRule="nonzero"
          />
          <path
            d="M184.2,519.7 C167.1,526.5 152,537.9 140.8,553.1 C132.5,536.1 119.7,522.1 104.1,512.3 C88.1,533.9 80.9,561.8 86.2,590.3 C91.6,618.8 108.5,642.1 131.3,656.4 C156.3,646.5 177.2,626.6 187.6,599.5 C198,572.4 195.9,543.8 184.2,519.7 Z"
            fill="#6DB28C"
            fillRule="nonzero"
          />
          <path
            d="M184.2,519.7 C167.1,526.5 152,537.9 140.8,553.1 C132.5,536.1 119.7,522.1 104.1,512.3 C88.1,533.9 80.9,561.8 86.2,590.3 C91.6,618.8 108.5,642.1 131.3,656.4 C156.3,646.5 177.2,626.6 187.6,599.5 C198,572.4 195.9,543.8 184.2,519.7 Z"
            fill="#5A9E77"
            fillRule="nonzero"
          />
          <path
            d="M367.6,600.4 C359.9,592.4 350.1,586.6 339.1,583.7 C346.7,575.2 351.6,564.9 353.5,554 C342.6,551.2 330.9,551.5 319.9,555.1 C321.9,543.7 320.6,532.1 316.3,521.7 C305.8,525.1 296.2,531.4 288.9,540.1 C284.6,529.6 277.4,520.7 268.4,514.2 C258,526.6 252.5,543.1 254.7,560.4 C256.8,577.7 266.2,592.4 279.3,601.9 C290.5,613.6 306.3,620.7 323.8,620.4 C341.2,620.1 356.8,612.4 367.6,600.4 Z"
            fill="#6DB28C"
            fillRule="nonzero"
          />
          <g transform="translate(201, 297)">
            <mask id="mask-14" fill="#fff">
              <use xlinkHref="#path-13" />
            </mask>
            <use
              id="Mask1"
              fill="#C26648"
              fillRule="nonzero"
              xlinkHref="#path-13"
            />
            <g
              opacity={0.15}
              mask="url(#mask-14)"
              stroke="#fff"
              strokeWidth={2}
            >
              <g transform="translate(-8, -3)">
                <path d="M1.6,20.8 C6.9,25.2 12.2,20.7 18.1,20.8 C20.4,20.8 23,21.4 24.3,23.4 C25.1,24.8 25.1,26.5 25,28.1 C24.5,36.7 17,39.4 12,45.6 C6.5,52.5 5.7,61.8 5.1,70.6" />
                <path d="M33.7,0.6 C32.4,5.2 33.9,10.5 37.5,13.6 C41.1,16.7 46.5,17.6 50.9,15.7" />
                <path d="M49.5,31.6 C45.5,32.4 41.3,33.2 37.7,35 C34,36.8 30.7,39.9 29.6,43.8 C27,52.8 29.5,62.5 26.8,71.4 C26.2,73.3 25.4,75.1 24.6,76.8 C20.4,86.1 16.6,96 19.5,106.4 C20.2,109 22.1,111.5 24.8,112 C25.8,112.2 26.9,112.1 28,112 C36,111 43.7,107 51.8,107.4" />
                <path d="M53,80 C46.8,80.3 40.5,82.3 36.2,86.7 C34.9,88.1 33.7,89.8 33.5,91.7 C33.3,93.6 34,95.7 35.7,96.7 C36.5,97.1 37.3,97.2 38.2,97.3 C42.8,97.4 47.6,94.4 51.7,96.6" />
                <path d="M50.5,119.4 C46.3,121.3 41.6,121.1 37,121.4 C32.4,121.7 27.5,122.8 24.7,126.5 C20.3,132.2 23.7,140.9 20.7,147.4 C19.1,151 15.8,153.5 12.2,155 C8.6,156.5 4.7,157.1 0.9,157.6" />
                <path d="M48.9,144.9 C41.9,143.3 34,146 29.4,151.6 C24.8,157.2 23.8,165.5 26.9,172 C28.9,176.2 32.3,179.7 33.6,184.2 C34.7,188 34,192.1 33.9,196.1 C33.8,200.1 34.3,204.4 37.2,207.2 C40.6,210.4 46,210.3 50.5,209.3 C51.3,209.1 52.2,208 51.4,207.7" />
                <path d="M6.5,185.6 C14.3,187.2 20.8,194.1 21.9,202 C23,209.9 19.6,219.3 24.9,225.3 C25.9,226.4 27.1,227.3 27.7,228.7 C28.3,230 28.3,231.5 28.2,232.9 C27.6,251.1 27,269.4 26.4,287.6 C26.3,291.8 25.6,296.9 21.9,298.9" />
                <path d="M3,227 C5.6,228.1 8.2,229.2 10.9,230.3 C11.9,230.7 13,231.2 13.8,232 C15.1,233.2 15.7,235.1 15.9,236.9 C16.3,240.2 15.8,243.5 15.3,246.7 C14.1,254.3 12.9,261.9 11.6,269.5" />
                <path d="M52.5,227.7 C46.7,229.5 40.3,231.8 37.7,237.3 C36.5,239.9 36.4,242.9 36.3,245.8 C36.2,249.2 36.1,252.5 36,255.9 C35.9,258.7 35.8,261.6 36.5,264.3 C37.6,269.4 41.2,273.8 45.9,276 C46.6,276.3 47.7,276 47.4,275.4" />
              </g>
            </g>
          </g>
          <path
            d="M201.7,607.3 L241,607.3 L241,528.1 C235.1,527.6 229.2,527.3 223.2,527.3 C215.9,527.3 208.8,527.7 201.7,528.5 L201.7,607.3 Z"
            fill="#865656"
            fillRule="nonzero"
            opacity={0.32}
          />
          <path
            d="M381.8,646.9 L346,653.4 L367,621.9 C353.6,603 336.6,586.8 317,574.4 L293.6,596.6 L298.5,564.2 C278.7,554.8 256.9,549 233.9,547.6 L223.1,607.2 L210.6,547.7 C187.4,549.3 165.5,555.4 145.7,565.2 L176.7,622.1 L131.1,573.2 C88,599.9 57.1,644.7 49.2,697 L397.2,697 C394.5,679.3 389.3,662.5 381.8,646.9 Z"
            fill="#C7D28A"
            fillRule="nonzero"
          />
          <path
            d="M152.1,626.9 C139.8,622.3 126.2,620.1 115.5,612.5 C105,605.1 98.5,593.3 93.5,581.4 C85.8,563.2 80.7,543.6 72.5,525.8 L41,538.7 L55,498.4 C40.8231172,482.647908 20.9538589,472.180032 2.03032036e-15,469.207995 L-8.8817842e-16,697 L210.3,697 C204,666.4 181.3,637.9 152.1,626.9 Z"
            fill="#6DB28C"
            fillRule="nonzero"
          />
          <path
            d="M129.1,686.5 C140.239263,691.164966 148.59371,694.664966 154.163341,697 L122.656482,697.004589 C124.703628,693.397891 126.876081,689.871748 129.1,686.5 Z M176.6,640.9 C164.3,648.4 153.1,657.6 143.4,668.3 C158.260566,681.056415 169.405991,690.623727 176.836274,697.001935 L183.337222,696.997519 C189.328951,696.59807 198.316543,695.998897 210.3,695.2 C205.9,673.9 193.6,654.5 176.6,640.9 Z"
            fill="#6DB28C"
            fillRule="nonzero"
            opacity={0.35}
          />
          <g
            opacity={0.35}
            transform="translate(100, 616)"
            fillRule="nonzero"
            fill="#506153"
          >
            <path
              d="M200.2,19.1 L160.6,69.7 L182.4,10.5 C168.9,5.1 154.4,1.8 139.2,0.8 L129.7,61.5 L120.2,0.8 C105,1.8 90.5,5.2 77,10.5 L98.8,69.7 L59.2,19.1 C46,26.6 34,36.1 23.8,47.3 L63,81 L196.6,81 L235.8,47.3 C225.4,36.2 213.4,26.6 200.2,19.1 Z"
              opacity={0.5}
            />
            <path
              d="M0.6,81 L49.4,81 L10.1,64.5 C6.7,69.7 3.5,75.3 0.6,81 Z"
              opacity={0.5}
            />
            <path
              d="M209.8,81 L258.7,81 C255.9,75.3 252.7,69.7 249.1,64.5 L209.8,81 Z"
              opacity={0.5}
            />
          </g>
          <g transform="translate(141, 626)" fillRule="nonzero" fill="#506153">
            <path d="M55.6,71 L174,71 L208.7,41.2 C199.7,31.3 189.1,22.9 177.3,16.2 L142.2,61 L161.5,8.6 C149.5,3.9 136.7,0.9 123.2,0 L114.8,53.7 L106.4,0 C92.9,0.9 80.1,3.9 68.1,8.6 L87.4,61 L52.3,16.1 C40.6,22.8 30,31.2 20.9,41.1 L55.6,71 Z" />
            <path d="M9,56.3 C5.9,61 3,65.9 0.5,71 L43.8,71 L9,56.3 Z" />
            <path d="M185.8,71 L229.1,71 C226.6,65.9 223.7,61 220.6,56.3 L185.8,71 Z" />
          </g>
          <g transform="translate(12, 578)" fillRule="nonzero" fill="#569C72">
            <path d="M0.8,3 C0.3,2.3 0.5,1.4 1.2,0.9 C1.9,0.5 2.8,0.6 3.3,1.3 L79.4575339,113.39792 C80.3575339,114.79792 80.0575339,116.59792 78.6575339,117.59792 C77.2575339,118.49792 75.3575339,118.19792 74.3575339,116.79792 C74.4242005,116.79792 49.9050226,78.8652798 0.8,3 Z" />
            <path d="M74.6,113.9 L93.6,74.1 C93.8,73.6 94.4,73.4 94.9,73.6 C95.4,73.8 95.6,74.4 95.4,74.9 L80.1,116.3 C79.5,117.9 77.8,118.7 76.2,118.1 C74.6,117.5 73.8,115.8 74.4,114.2 C74.5,114.1 74.6,114 74.6,113.9 Z" />
            <path d="M62.9,99.9 L9.7,97.9 C9.1,97.9 8.7,97.4 8.7,96.9 C8.7,96.4 9.1,96 9.7,95.9 L62.9,93.9 C64.6,93.8 66,95.1 66,96.8 C66,98.5 64.8,99.9 63.1,99.9 C63.1,99.9 63,99.9 62.9,99.9 Z" />
            <path d="M37.4,57.2 L48.5,19 C48.7,18.5 49.2,18.2 49.7,18.3 C50.2,18.4 50.5,18.9 50.4,19.4 L43.2,58.5 C42.9,60.1 41.3,61.2 39.7,60.9 C38.1,60.6 37,59 37.3,57.4 C37.4,57.4 37.4,57.3 37.4,57.2 Z" />
          </g>
          <g transform="translate(34, 61)" fillRule="nonzero">
            <path
              d="M30.2,94.5 C39.8,97.4 47.3,107.2 49.1,117 C77.5,97.7 91.2,105.5 107.1,118.2 C107,106 98.2,94.4 86.5,91.1 C74.8,87.8 61.2,92.8 54.6,103.1 C51.4,92.1 40.1,84.5 28.7,84.3 C18.9,84.1 7.1,89 1.2,97.1 C10.8,93.9 25.4,93 30.2,94.5 Z"
              fill="#5A9E77"
            />
            <ellipse
              fill="#498D65"
              transform="translate(12.504730, 218.794630) rotate(-45) translate(-12.504730, -218.794630) "
              cx={12.50473}
              cy={218.79463}
              rx={12.199883}
              ry={5.29994917}
            />
            <ellipse
              fill="#498D65"
              transform="translate(75.260530, 342.981870) rotate(-45) translate(-75.260530, -342.981870) "
              cx={75.26053}
              cy={342.98187}
              rx={5.29994917}
              ry={12.199883}
            />
            <ellipse
              fill="#498D65"
              transform="translate(303.394820, 263.758340) rotate(-45) translate(-303.394820, -263.758340) "
              cx={303.39482}
              cy={263.75834}
              rx={5.29994917}
              ry={12.199883}
            />
            <ellipse
              fill="#498D65"
              transform="translate(245.983300, 326.992400) rotate(-45) translate(-245.983300, -326.992400) "
              cx={245.9833}
              cy={326.9924}
              rx={5.29994917}
              ry={12.199883}
            />
            <ellipse
              fill="#5A9E77"
              transform="translate(34.031990, 151.918990) rotate(-45) translate(-34.031990, -151.918990) "
              cx={34.03199}
              cy={151.91899}
              rx={5.29994917}
              ry={12.199883}
            />
            <path
              d="M404.8,157 C413.6,145.1 428.5,138.1 443.2,139 C436.4,134.9 428.1,132.9 420.4,135 C412.8,137.1 406.2,143.6 405.3,151.5 C399.6,148.3 392.6,147.7 386.4,149.8 C381.3,151.6 375.6,155.8 373.7,161.1 C375.8,161.1 378.3,158.5 380.3,157.5 C382.8,156.3 385.4,155.4 388.1,155 C393.8,154 399.7,154.6 404.8,157 Z"
              fill="#5A9E77"
            />
            <path
              d="M150.4,9.3 C144.6,2.1 134.4,-1.3 125.5,1.1 C119.6,2.7 112.6,7.8 110,17.5 C119.3,4 139.7,5.3 150.4,9.3 Z"
              fill="#5A9E77"
            />
          </g>
          <path
            d="M425.5,656.1 L448.6,664.2 L501.1,664.2 L524.2,656.1 C516.4,652.2 507.2,651.3 498.3,654.4 C496,655.2 493.9,656.2 492,657.4 L510.9,636.2 C502.2,636.6 493.7,640.3 487.4,647.3 C485.8,649.1 484.5,651 483.3,653 L489.5,625.3 C482.6,629.6 477.2,636.4 474.9,644.7 C472.6,636.3 467.2,629.6 460.3,625.3 L466.5,653 C465.4,651 464,649.1 462.4,647.3 C456.2,640.3 447.6,636.6 438.9,636.2 L457.8,657.4 C455.8,656.2 453.7,655.2 451.5,654.4 C442.6,651.3 433.3,652.2 425.5,656.1 Z"
            fill="#E9AD60"
            fillRule="nonzero"
          />
          <path
            d="M377.1,555.5 L391.7,560.6 L425,560.6 L439.6,555.5 C434.7,553 428.8,552.5 423.2,554.4 C421.8,554.9 420.4,555.5 419.2,556.3 L431.2,542.8 C425.7,543.1 420.3,545.4 416.4,549.8 C415.4,550.9 414.5,552.2 413.8,553.4 L417.7,535.8 C413.3,538.5 409.9,542.8 408.4,548.1 C406.9,542.8 403.5,538.5 399.1,535.8 L403,553.4 C402.3,552.1 401.4,550.9 400.4,549.8 C396.5,545.4 391.1,543 385.6,542.8 L397.6,556.3 C396.4,555.5 395,554.9 393.6,554.4 C387.9,552.5 382.1,553.1 377.1,555.5 Z"
            fill="#6DB28C"
            fillRule="nonzero"
          />
          <g transform="translate(533, 653)" fillRule="nonzero" fill="#C26648">
            <path d="M42.8,11.1 L76.6,11.1 C73.7,4.7 67.2,0.2 59.7,0.2 C52.2,0.2 45.7,4.7 42.8,11.1 Z" />
            <path d="M0.5,11.1 L24,11.1 C22,6.6 17.5,3.5 12.3,3.5 C7,3.6 2.5,6.7 0.5,11.1 Z" />
          </g>
          <g id="TreeHouse" transform="translate(39, 118)" fillRule="nonzero">
            <rect fill="#C26648" x={0.5} y={243} width={163} height={13.4} />
            <rect
              fill="#E9AD60"
              x={31.8}
              y={221.600006}
              width={131}
              height={21.5}
            />
            <rect fill="#E9AD60" x={51.2} y={200.3} width={111.5} height={22} />
            <rect fill="#E9AD60" x={70.3} y={179} width={92.5} height={22} />
            <polygon
              fill="#DE7782"
              opacity={0.25}
              points="144.3 179 144.3 200.3 125.3 200.3 125.3 221.7 105.8 221.7 105.8 243 162.7 243 162.7 179"
            />
            <path
              d="M118.5,269.9 C115.7,265.7 113.3,261.2 111.4,256.5 L90.1,256.5 C91.6,261.1 93.4,265.6 95.5,269.9 L118.5,269.9 Z"
              fill="#865656"
              opacity={0.32}
            />
            <rect fill="#C26648" x={70.3} y={165.6} width={247.3} height={14} />
            <rect
              fill="#865656"
              opacity={0.32}
              x={162.7}
              y={179}
              width={39.3}
              height={13.4}
            />
            <path
              d="M269.3,192.4 C271.4,188.1 273.2,183.6 274.7,179 L253.4,179 C251.5,183.7 249.1,188.2 246.3,192.4 L269.3,192.4 Z"
              fill="#865656"
              opacity={0.32}
            />
            <rect fill="#E9AD60" x={92.9} y={6} width={203.7} height={159.6} />
            <rect
              fill="#DE7782"
              opacity={0.25}
              x={92.9}
              y={132.4}
              width={203.7}
              height={5.2}
            />
            <rect
              fill="#DE7782"
              opacity={0.25}
              x={92.9}
              y={85.8}
              width={203.7}
              height={5.2}
            />
            <rect
              fill="#DE7782"
              opacity={0.25}
              x={92.9}
              y={39.2}
              width={203.7}
              height={5.2}
            />
            <rect
              fill="#506153"
              x={211.4}
              y={39.2}
              width={69.7}
              height={93.2}
            />
            <rect fill="#C26648" x={112.6} y={39} width={69.7} height={127} />
            <rect
              fill="#AC5942"
              x={124.9}
              y={52.1}
              width={45.1}
              height={46.3}
            />
            <polygon
              fill="#C26648"
              points="170.7 72.7 150.1 72.7 150.1 52.1 144.9 52.1 144.9 72.7 124.3 72.7 124.3 77.9 144.9 77.9 144.9 98.4 150.1 98.4 150.1 77.9 170.7 77.9"
            />
            <path
              d="M72.1,18.2 C84.4,18.2 84.4,12.2 96.6,12.2 C108.8,12.2 108.9,18.2 121.1,18.2 C133.4,18.2 133.4,12.2 145.6,12.2 C157.9,12.2 157.9,18.2 170.1,18.2 C182.4,18.2 182.4,12.2 194.6,12.2 C206.9,12.2 206.9,18.2 219.1,18.2 C231.4,18.2 231.4,12.2 243.6,12.2 C255.9,12.2 255.9,18.2 268.1,18.2 C280.4,18.2 280.4,12.2 292.6,12.2 C304.9,12.2 304.9,18.2 317.1,18.2 L317.1,0.2 L72.1,0.2 L72.1,18.2 Z"
              fill="#6DB28C"
            />
            <circle fill="#E9AD60" cx={164.9} cy={111.8} r={3.9} />
            <rect
              fill="#958378"
              x={33.2}
              y={256.4}
              width={5.4}
              height={142.1}
            />
          </g>
          <g id="Person2" transform="translate(258, 166)" fillRule="nonzero">
            <polygon
              fill="#7A5F5F"
              points="41.6 80.9 62.1 60 62.1 52.1 54.1 57.1 58.2 58.6 38.1 72.7"
            />
            <path
              d="M10,84.4 L2.4,54 C0.6,46.6 6.1,39.5 13.7,39.5 L25.5,39.5 C30.5,39.5 34.6,43.6 34.6,48.6 L34.6,48.6 L39.2,53.2 C41.1,55.1 41.8,57.9 41,60.5 L37.6,71.2 L43.3,84.5 L10,84.4 Z"
              fill="#BAC76E"
            />
            <path
              d="M22.6,74.5 L2,50.6 C2,50.7 2,50.9 2,51 L2,51.4 C2,52.2 2.1,53.1 2.3,54 L9.9,84.4 L43,84.4 L43,84.3 L37.3,71.1 L22.6,74.5 Z"
              fill="#7A5F5F"
              opacity={0.25}
            />
            <polygon
              fill="#7A5F5F"
              points="54.1 57.1 48.7 55.2 59.6 39.5 67.1 44.7 62.1 52.1"
            />
            <path
              d="M55.6,62 L26.4,79.9 C22.3,82.3 17.1,80.8 14.9,76.6 L2.7,52.9 C0.8,49.1 3.5,44.6 7.7,44.6 L7.7,44.6 C9.9,44.6 11.8,45.8 12.8,47.8 L24.2,70.9 L47.8,63 L47.8,56.6 L51.9,50.6 C53.5,52.2 50.1,57.4 50.1,57.4 L50.1,62.3 C50.1,62.4 53.8,60.8 55.6,62 Z"
              fill="#958378"
            />
            <path
              d="M25.5,39.5 L23.8,39.5 L34.5,48.6 C34.5,43.6 30.5,39.5 25.5,39.5 Z"
              fill="#7A5F5F"
              opacity={0.25}
            />
            <circle fill="#7A5F5F" cx={16.9} cy={16.8} r={16} />
            <path
              d="M12.3,39.5 L13.8,30.8 L11.1,24.9 C10.1,22.6 10,20 10.9,17.6 L10.9,17.6 C12.6,13.2 17.2,10.7 21.9,11.7 L23.3,12 C28,13.1 31.2,17.5 30.6,22.3 L30.1,27 L31,29.1 L28.8,30.8 L28.5,33.2 C28.2,35.5 26.1,37.1 23.8,36.7 L23.8,36.7 L23.8,39.4 L12.3,39.4 L12.3,39.5 Z"
              fill="#958378"
            />
            <polygon fill="#958378" points="12.3 39.5 25.7 46.7 23.8 36.9" />
            <polygon fill="#7A5F5F" points="23.8 36.9 17.5 35.7 24.9 42.6" />
            <path
              d="M29.2,15.6 L19,28 L17,23 L16.6,23 C15.1,23 13.9,24.2 13.9,25.7 L13.9,25.7 C13.9,26.9 14.6,27.9 15.8,28.3 L17,28.7 L13.9,30.9 L7.1,28.7 L9.2,10.3 L22.7,7.5 L29.2,15.6 Z"
              fill="#7A5F5F"
            />
            <polygon
              fill="#7A5F5F"
              opacity={0.25}
              points="12.8 47.9 25.7 68.3 39.1 65.9 24.2 70.9"
            />
          </g>
          <g transform="translate(208, 68)">
            <g id="h-tree-aura-o" opacity={0}>
              <circle
                fill="#fff"
                fillRule="nonzero"
                cx={74.7}
                cy={4.3}
                r={3.5}
              />
              <circle
                fill="#fff"
                fillRule="nonzero"
                cx={188.2}
                cy={117.7}
                r={3.5}
              />
              <g stroke="#fff" strokeWidth={2}>
                <path d="M104.6,7.3 C123.4,11.9 141.3,21.6 156,36.3" />
                <path d="M0.9,36.3 C15.2,22 32.5,12.5 50.8,7.7" />
                <path d="M184.9,87.6 C180.3,68.8 170.6,50.9 155.9,36.2" />
                <path d="M156,191.3 C170.3,177 179.8,159.7 184.6,141.4" />
              </g>
            </g>
            <g id="h-tree-aura-i" opacity={0}>
              <circle
                fill="#fff"
                fillRule="nonzero"
                cx={140.6}
                cy={51.7}
                r={3.5}
              />
              <circle
                fill="#fff"
                fillRule="nonzero"
                cx={16.3}
                cy={51.7}
                r={3.5}
              />
              <circle
                fill="#fff"
                fillRule="nonzero"
                cx={140.6}
                cy={175.9}
                r={3.5}
              />
              <g stroke="#fff" strokeWidth={2}>
                <path d="M35.4,37.2 C61.3,22.6 93.1,22.2 119.3,36" />
                <path d="M155.1,156.8 C169.7,130.9 170.1,99.1 156.3,72.9" />
              </g>
            </g>
          </g>
        </g>
        <g
          id="UI"
          transform="translate(553, 66)"
          fillRule="nonzero"
          fill="#fff"
        >
          <g id="Logo">
            <rect
              transform="translate(27.742870, 18.905290) rotate(-63.429824) translate(-27.742870, -18.905290) "
              x={7.54258064}
              y={18.0052771}
              width={40.4005787}
              height={1.80002578}
            />
            <rect
              transform="translate(68.639040, 18.896630) rotate(-63.429824) translate(-68.639040, -18.896630) "
              x={48.4387506}
              y={17.9966171}
              width={40.4005787}
              height={1.80002578}
            />
            <rect x={66.3} y={22.1} width={21.4} height={1.8} />
            <rect
              transform="translate(10.395140, 18.921580) rotate(-26.565051) translate(-10.395140, -18.921580) "
              x={9.49516736}
              y={-1.27780591}
              width={1.79994528}
              height={40.3987718}
            />
            <rect
              transform="translate(256.743270, 18.892690) rotate(-63.429824) translate(-256.743270, -18.892690) "
              x={236.542981}
              y={17.9926771}
              width={40.4005787}
              height={1.80002578}
            />
            <rect
              transform="translate(239.386840, 18.917080) rotate(-26.565051) translate(-239.386840, -18.917080) "
              x={238.486867}
              y={-1.28230591}
              width={1.79994528}
              height={40.3987718}
            />
            <rect
              transform="translate(199.833440, 10.190120) rotate(-45.008103) translate(-199.833440, -10.190120) "
              x={186.733565}
              y={9.29012862}
              width={26.199749}
              height={1.79998276}
            />
            <rect
              transform="translate(182.573080, 10.250660) rotate(-45) translate(-182.573080, -10.250660) "
              x={181.673089}
              y={-2.84921437}
              width={1.79998274}
              height={26.1997487}
            />
            <rect
              transform="translate(85.884440, 18.886380) rotate(-26.565051) translate(-85.884440, -18.886380) "
              x={84.9844674}
              y={-1.31300591}
              width={1.79994528}
              height={40.3987718}
            />
            <rect x={0.9} y={0.7} width={1.8} height={36.3} />
            <rect
              transform="translate(134.015590, 18.918890) rotate(-45) translate(-134.015590, -18.918890) "
              x={133.115599}
              y={-6.38086737}
              width={1.79998274}
              height={50.5995147}
            />
            <rect x={115.9} y={0.7} width={1.8} height={36.3} />
            <rect x={35.4} y={0.7} width={1.8} height={36.3} />
            <rect x={286.7} y={0.7} width={1.8} height={36.3} />
            <rect x={343.5} y={0.7} width={1.8} height={36.3} />
            <rect
              transform="translate(361.608980, 27.518940) rotate(-62.819991) translate(-361.608980, -27.518940) "
              x={360.708956}
              y={7.71840145}
              width={1.80004896}
              height={39.6010771}
            />
            <rect x={286.2} y={0.7} width={36.8} height={1.8} />
            <rect x={287.6} y={18} width={21.4} height={1.8} />
            <rect x={286.2} y={35.2} width={36.8} height={1.8} />
            <rect x={457.1} y={0.7} width={1.8} height={36.3} />
            <rect x={456.6} y={0.7} width={36.8} height={1.8} />
            <rect x={458} y={18} width={21.4} height={1.8} />
            <rect x={456.6} y={35.2} width={36.8} height={1.8} />
            <rect x={150.4} y={0.7} width={1.8} height={36.3} />
            <rect x={190.4} y={18} width={1.8} height={19.1} />
            <path d="M370.3,19.8 L343.6,19.8 L343.6,18 L370.3,18 C374.6,18 378,14.5 378,10.3 C378,6 374.5,2.6 370.3,2.6 L343.6,2.6 L343.6,0.8 L370.3,0.8 C375.6,0.8 379.8,5.1 379.8,10.3 C379.8,15.5 375.6,19.8 370.3,19.8 Z" />
            <path d="M427.6,37.1 L400.9,37.1 L400.9,35.3 L427.6,35.3 C431.9,35.3 435.3,31.8 435.3,27.6 C435.3,23.3 431.8,19.9 427.6,19.9 L410.4,19.9 L410.4,18 L427.6,18 C432.9,18 437.1,22.3 437.1,27.5 C437.1,32.8 432.8,37.1 427.6,37.1 Z" />
            <path d="M426.2,19.8 L409.9,19.8 C404.6,19.8 400.4,15.5 400.4,10.3 C400.4,5 404.7,0.8 409.9,0.8 L436.6,0.8 L436.6,2.6 L409.9,2.6 C405.6,2.6 402.2,6.1 402.2,10.3 C402.2,14.6 405.7,18 409.9,18 L426.2,18 L426.2,19.8 Z" />
          </g>
          <text
            style={{
              fontFamily: "'Signika'",
              fontSize: 36,
              fill: '#fff',
            }}
            y={90}
            x={28}
          >
            {'A peer-to-peer social network'}
          </text>
        </g>
      </g>
    </svg>
  </div>
)

export default Hero
