import React from 'react'
import SEO from '../components/seo'
import Hero from '../components/home/hero'
import CallToAction from '../components/home/cta'
import WhatSection from '../components/home/what'
import FreeSection from '../components/home/free'
import SocialGraph from '../components/home/social-graph'
import Download from '../components/home/download'
import Donate from '../components/home/donate'
import Footer from '../components/footer'

import './home.css'

const IndexPage = () => (
  <>
    <SEO
      title="Manyverse – a peer-to-peer social network"
      forceTitle={true}
      keywords={[`manyverse`, `scuttlebutt`, `mobile`, `ssb`, `off-grid`]}
    />

    <Hero />
    <CallToAction />
    <WhatSection />
    <FreeSection />
    <SocialGraph />
    <Download />
    <Donate />
    <Footer />
  </>
)

export default IndexPage
