import React from 'react'
import styles from './styles.module.css'
import screenshotDesktop from '../../../images/screenshot-desktop3.png'
import screenshotMobile from '../../../images/screenshot5.png'

const WhatSection = props => (
  <>
    <section className={styles.firstWhatSection}>
      <div className={styles.whatText} style={{textAlign: 'center'}}>
        <h1>What is Manyverse?</h1>
        <p>
          A social network without the bad stuff, built on the peer-to-peer{' '}
          <a href="https://ssbc.github.io/scuttlebutt-protocol-guide/">
            SSB protocol
          </a>.
          It's free and open source, and available for desktop and mobile.
        </p>
      </div>

      <div className={styles.laptopShell}>
        <img
          src={screenshotDesktop}
          width="100%"
          alt="Screenshot of the Manyverse desktop app"
        />
        <div className={styles.laptopFooter} />
      </div>
    </section>

    <section className={styles.secondWhatSection}>
      <div className={styles.phoneShell}>
        <div className={styles.phoneHeader} />
        <img
          src={screenshotMobile}
          width="100%"
          alt="Screenshot of the Manyverse mobile app"
        />
        <div className={styles.phoneFooter} />
      </div>

      <div className={styles.whatText}>
        <h1>Familiar, yet radically new</h1>
        <p>
          Manyverse is a social networking app with features you would expect:
          posts, likes, profiles, private messages, etc. But it's not running in
          the cloud owned by a company, instead, your friends' posts and all
          your social <strong>data live entirely in your phone</strong>. This
          way, even when you're offline, you can scroll, read anything, and even
          write posts and like content! When your phone is back online, it{' '}
          <strong>syncs the latest updates directly</strong> with your friends'
          phones, through a shared local Wi-Fi or on the internet.{' '}
        </p>
        <p>
          We're building this free and open source project as a community effort
          because we believe in non-commercial, neutral, and fair mobile
          communication for everyone.
        </p>
      </div>
    </section>
  </>
)

export default WhatSection
