import React from 'react'
import styles from './styles.module.css'

const FreeSection = props => (
  <section className={styles.freeSection}>
    <div className={styles.freeSectionLeft} />
    <div className={styles.freeSectionRight} />
    <div className={styles.content}>
      <h1>
        Forever
        <br />
        free
      </h1>
      <div className={styles.separator} />
      <p>
        No ads.
        <br />
        No pay wall.
        <br />
        No data centers.
        <br />
        No cloud. No cookies.
        <br />
        No company. No investors.
        <br />
        No token. No ICO. No blockchain.
        <br />
        No tracking. No spying. No analytics.
        <br />
        <span className={styles.pyr2}>
          No tedious registration. No premium costs.
        </span>
        <br />
        <span className={styles.pyr1}>
          No annoying notifications, emails, and banners.
        </span>
      </p>
    </div>
  </section>
)

export default FreeSection
