import React from 'react'
import styles from './styles.module.css'
import {Link} from 'gatsby'
import videoUrl from '../../../images/donate.mp4'

const Donate = props => (
  <div className={styles.donate}>
    <video autoPlay={true} loop={true} muted={true}>
      <source type="video/mp4" src={videoUrl} />
    </video>
    <div className={styles.gradient} />
    <div className={styles.content}>
      <h1>
        Become a <strong style={{fontWeight: 'bold'}}>backer</strong>
      </h1>
      <p>Donate to buy us time, or contribute to our open source</p>
      <p>Let's do this together!</p>
      <Link className={styles.btn} to="/contribute">
        Contribute
      </Link>
    </div>
  </div>
)

export default Donate
